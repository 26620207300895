<template>
  <div class="user_course_list_page">
    <div class="check_btn" @click="handleToCheck">选课（{{restClassCount}}）</div>
    <div class="right_box_container">
      <my-tabs :tabs="tabs" v-model:activeTab="activeTab" class="tabs"></my-tabs>
      <div class="cards_container" v-if="userCourseList&&userCourseList.length">
        <course-list-card
          v-for="item in userCourseList"
          :key="item.chargeCourseId"
          :course-info="item"
        ></course-list-card>
      </div>
      <p v-else class="empty_tip">暂时还没有课程哦～快去添加课程吧</p>
    </div>
  </div>
</template>

<script>
import MyTabs from '@/components/MyTabs.vue';
import CourseListCard from '@/components/CourseListCard.vue';
import { connectState } from '@/utils/wyUtil';

export default {
  components: { MyTabs, CourseListCard },
  data() {
    return {
      activeTab: 0,
      tabs: [
        { label: '全部课程（0）', value: 0 },
        // { label: '已过期', value: 1 },
      ],
    };
  },
  computed: {
    ...connectState('course', ['restClassCount', 'userCourseList']),
  },
  watch: {
    userCourseList(val) {
      this.tabs = [
        { label: `全部课程（${(val || []).length}）`, value: 0 },
        // { label: '已过期', value: 1 },
      ];
    },
  },
  created() {
    this.getStudyCardCount();
    this.getUserCourses();
  },
  methods: {
    getUserCourses() {
      this.$store.dispatch('course/fetchUserCourses');
    },
    getStudyCardCount() {
      this.$store.dispatch('course/getRestClassCourse');
    },
    handleToCheck() {
      if (this.restClassCount <= 0) {
        this.$message.error('您的剩余学习卡课时为0，请先购买哦！');
        return false;
      }
      this.$router.push('/checkList');
      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
  .user_course_list_page{
    .right_box_container{
      width: 965px;
      height: auto;
      min-height:500px;
      background: #FFFFFF;
      border-radius: 6px;
      padding:15px 0;
    }
    .check_btn{
      width:120px;
      height: 38px;
      line-height: 38px;
      text-align: center;
      font-weight: 500;
      background: #3377FF;
      border-radius: 6px;
      font-size:16px;
      margin-bottom:20px;
      color:#FFF;
      margin-left: 0;
      cursor: pointer;
    }
    .tabs{
      padding:5px 15px 0;
      border-bottom: 2px solid #F8F8F8;
      margin-bottom:20px;
    }
    .cards_container{
      padding:0 15px;
      width: 965px;
      height: auto;

      display:flex;
      flex-flow:row wrap;
      align-items: flex-start;

    }
    .empty_tip{
      width:300px;
      height: 22px;
      font-size: 16px;
      font-weight: 400;
      text-align: center;
      color: #CACDD6;
      line-height: 22px;
     margin:100px auto;

    }

  }
</style>
