<template>
  <div class="common_course_card_wrapper" :key="courseInfo.chargeCourseId" @click="handleToCourse">
    <img class="card_image" :src="courseInfo.coverUrl" alt="" />
    <p class="title">
      {{ courseInfo.chargeCourseName }}
    </p>
    <p class="desc">
      <span class="teaches_names">
        {{ wrapTeacher() }}
      </span>
      <span v-show="wrapDoneStatus().type !== 'start'">
        <span class="primary">
          {{ courseInfo.doneSection }}
        </span>
        <span class="gray">
          {{ `/${courseInfo.sectionCount}已学` }}
        </span>
      </span>
    </p>
    <div class="line"></div>
    <p class="plain_button" :class="wrapDoneStatus().type === 'finish' ? 'gray' : 'primary'">
      {{ wrapDoneStatus().text }}
    </p>
    <el-dialog v-model="dialogVisible" title="提示" :before-close="handleClose" center :show-close="false"
      :close-on-press-escape="false" :close-on-click-modal="false">
      <div class="dialog_content">该课程目前仅支持在雅思哥机考软件、雅思哥最新版 APP观看哦!</div>
      <img @click="handleClose" class="close_btn" src="../assets/img/course/close_btn.png" alt="close_btn" />
      <template #footer>
        <el-button @click="handleClose" class="btn">知道了</el-button>
        <el-button type="primary" @click="handleDownload" class="btn">下载机考软件</el-button>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { pagePush } from '../utils/wyUtil';
import http from '@/utils/request';

export default {
  name: 'courseListCard',
  props: {
    handleClick: {
      type: Function,
      default: () => { },
    },
    courseInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      dialogVisible: false
    };
  },
  methods: {
    async handleToCourse() {
      const res = await http(`/hcp/yida/chargeCourse/detail?chargeCourseId=${this.courseInfo.chargeCourseId}`);
      if (res) {
        if (res.supplierType === 1) {
          // 百家云
          this.dialogVisible = true
        } else if (res.supplierType === 0) {
          //欢拓
          pagePush(`/courseDetail/${this.courseInfo.chargeCourseId}`);
        }
      }
    },
    wrapDoneStatus() {
      const { doneSection, sectionCount } = this.courseInfo;
      if (doneSection === 0) {
        return { type: 'start', text: '开始学习' };
      } if (doneSection < sectionCount) {
        return { type: 'ongoing', text: '去学习' };
      }
      return { type: 'finish', text: '已完成' };
    },
    wrapTeacher() {
      const { teacherIdAndNameList = [] } = this.courseInfo;
      const arr = teacherIdAndNameList.map((item) => item.teacherName);
      return `${arr.join('、')}`;
    },
    handleClose() {
      this.dialogVisible = false;
    },
    handleDownload() {
      window.location.href = 'https://www.ieltsbro.com/pc'
    }
  },
};
</script>
<style lang="scss" scoped>
.common_course_card_wrapper {
  width: 292px;
  background: #FFFFFF;
  box-shadow: 0px 2px 16px 0px rgba(202, 205, 214, 0.47);
  border-radius: 6px;
  margin-bottom: 20px;
  margin-right: 25px;
  overflow: hidden;
  cursor: pointer;

  &:nth-child(3n) {
    margin-right: 0px;
  }

  .card_image {
    width: 292px;
    height: 200px;
    background: #EEE;
  }

  .title {
    width: 291px;
    height: 44px;
    font-size: 16px;
    font-weight: bold;
    color: #333333;
    line-height: 22px;
    overflow: hidden;
    margin-top: 5px;
    padding: 0 10px;
  }

  .desc {
    height: 35px;
    font-size: 14px;
    font-weight: 400;
    color: #A0A2AB;
    line-height: 20px;
    padding: 5px 10px 10px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .teaches_names {
      width: 200px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

  }

  .line {
    width: 100%;
    height: 1px;
    background: rgba(114, 119, 126, 0.1);
  }

  .plain_button {

    height: 38px;
    font-size: 14px;
    font-weight: 500;

    line-height: 38px;
    text-align: center;

  }

  .primary {
    color: #3377FF;
  }

  .gray {
    color: #A0A2AB;
  }

  .dialog_content {
    text-align: center;
    font-size: 16px;
    color: #333643;
    font-style: normal;
  }

  .close_btn {
    position: absolute;
    top: 24px;
    right: 24px;
    width: 20px;
    height: 20px;
  }

  ::v-deep .el-dialog__footer {
    position: absolute;
    bottom: 0;
    right: 0;
  }

  ::v-deep .el-dialog__header {
    position: relative;
    padding: 40px 20px 24px;
  }

  ::v-deep .el-dialog {
    display: flex;
    flex-direction: column;
    width: 527px !important;
    height: 293px;
  }

}
</style>
